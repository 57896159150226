const initialState = {
  markersArray:[],
  userPositionsArray:[],
  reportMarksArray:[],
  subscriptionsArray:[],
  chatHeadersArray:[],
  newsFeedContent:[],
  notificationArray:[],
  showErrorMessageScreen:false,
  myProfile: {},
  token:null,
  displayMap: false,
  displayFABs: true,
  scoredPoints : null,
  registrationData : {
    userNickname:"",
    userEmail:"",
    newPassword1:"",
    newPassword2:"",
    userDescription:"",
    userBoat:"",
    userDisplayBoatName : false,
    userBoatName : "",
    userDisplayBoatType : false,
    userBoatType : "",
    userDisplayBoatHarbor : false,
    userBoatModel : "",
    userDisplayBoatModel : false,
    userBoatHarbor : "",
    userMailNotification:true,
    userPositionShare:true,
    isNicknameUnique:true,
    isEmailUnique:true,
    isFetching : false,
    isRegistered: false,
    alertObject : null,
    registrationStep : 1,
    isFetching : false,
    isResizingPic : false,
    isResizingAvatar : false,
  }
}

export default function rootReducer (state = initialState, action) {
  console.log('Reducer -> Switch action du reducer :')
  switch (action.type) {
    case 'LOAD_MARKERS_ARRAY':
      console.log('Reducer -> LOAD_MARKERS_ARRAY')
      return {
        ...state,
        markersArray: action.payload
      }

    case 'LOAD_USERPOSITIONS_ARRAY':
      console.log('Reducer -> LOAD_USERPOSITIONS_ARRAY')
      return {
        ...state,
        userPositionsArray: action.payload
      }
  
      case 'LOAD_SUBSCRIPTIONS_ARRAY':
      console.log('Reducer -> LOAD_SUBSCRIPTIONS_ARRAY')
      return {
        ...state,
        subscriptionsArray: action.payload
      }

    case 'LOAD_REPORTMARKS_ARRAY':
      console.log('Reducer -> LOAD_REPORTMARKS_ARRAY')
      return {
        ...state,
        reportMarksArray: action.payload
      }

    case 'RESET_SUBSCRIPTIONS_ARRAY':
      console.log('Reducer -> RESET_SUBSCRIPTIONS_ARRAY')
      return {
        ...state,
        subscriptionsArray: []
      }
  
      case 'LOAD_CHAT_HEADERS_ARRAY':
      console.log('Reducer -> LOAD_CHAT_HEADERS_ARRAY')
      return {
        ...state,
        chatHeadersArray: action.payload
      }

    case 'RESET_CHAT_HEADERS_ARRAY':
    console.log('Reducer -> RESET_CHAT_HEADERS_ARRAY')
    return {
      ...state,
      chatHeadersArray: []
    }

    case 'LOAD_NEWS_FEED_CONTENT':
      console.log('Reducer -> LOAD_NEWS_FEED_CONTENT')
      return {
        ...state,
        newsFeedContent: action.payload
      }

    case 'RESET_NEWS_FEED_CONTENT':
      console.log('Reducer -> RESET_NEWS_FEED_CONTENT')
      return {
        ...state,
        newsFeedContent: []
      }
  
    case 'LOAD_NOTIFICATION_ARRAY':
      console.log('Reducer -> LOAD_NOTIFICATION_ARRAY')
      return {
        ...state,
        notificationArray: action.payload
      }

    case 'RESET_NOTIFICATION_ARRAY':
      console.log('Reducer -> RESET_NOTIFICATION_ARRAY')
      return {
        ...state,
        notificationArray: []
      }

    case 'TOGGLE_ERROR_MESSAGE_SCREEN':
      console.log('Reducer -> TOGGLE_ERROR_MESSAGE_SCREEN')
      return {
        ...state,
        showErrorMessageScreen: action.payload
      }

    case 'LOAD_MY_PROFILE':
      console.log('Reducer -> LOAD_MY_PROFILE')
      return {
        ...state,
        myProfile: action.payload
      }

    case 'RESET_MY_PROFILE':
      console.log('Reducer -> RESET_MY_PROFILE')
      return {
        ...state,
        myProfile: {}
      }
  
    case 'LOAD_TOKEN':
      console.log('Reducer -> LOAD_TOKEN')
      return {
        ...state,
        token: action.payload
      }

    case 'RESET_TOKEN':
      console.log('Reducer -> RESET_TOKEN')
      return {
        ...state,
        token: null
      }

    case 'DISPLAY_MAP':
      console.log('Reducer -> DISPLAY_MAP')
      return {
        ...state,
        displayMap: true
      }

    case 'HIDE_MAP':
      console.log('Reducer -> HIDE_MAP')
      return {
        ...state,
        displayMap: false
      }
   
    case 'DISPLAY_FABS':
      console.log('Reducer -> DISPLAY_FABS')
      return {
        ...state,
        displayFABs: true
      }

    case 'HIDE_FABS':
      console.log('Reducer -> HIDE_FABS')
      return {
        ...state,
        displayFABs: false
      }
    
    case 'TOGGLE_SCOREDPOINTS_ANIMATION':
      console.log('Reducer -> TOGGLE_SCOREDPOINTS_ANIMATION')
      return {
        ...state,
        scoredPoints : action.payload
      }
  
    case 'UPDATE_REGISTRATION_DATA':
      console.log('Reducer -> SAVE_REGISTRATION_DATA')
      return {
        ...state,
        registrationData : {
          ...state.registrationData,
          ...action.payload
        }
      }
    
    case 'RESET_REGISTRATION_DATA':
      console.log('Reducer -> RESET_REGISTRATION_DATA')
      return {
        ...state,
        registrationData : initialState.registrationData
      }
        
    default:
      console.log('Reducer -> Aucune action lancée')

    return state
  }
}