import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import Post from './Post'
import GetFunction from '../API/getFunction';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import SendIcon from '@mui/icons-material/Send';
import Fab from '@mui/material/Fab';

// Déclaration reat-router
import { useNavigate } from 'react-router-dom'


export default function NewsFeed() {
  // Connexion au store REDUX
  const newsFeedContent = useSelector(state => state.newsFeedContent)

  const selectMarkersArray = state => state.markersArray
  const markersArray = useSelector(selectMarkersArray)

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const selectSubscriptionsArray = state => state.subscriptionsArray
  const subscriptionsArray = useSelector(selectSubscriptionsArray)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  // State du contennu
  const [feedList, setFeedList] = useState()

  const [subscribedMarkersArray, setSubscribedMarkersArray] = useState([])

  // pull-to-refresh
  const [startPoint, setStartPoint] = useState(0);
  const [pullChange, setPullChange] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);

  // Hook qui contruit le contenu du fil à chaque fois que le contenu du newsFeed change
  // (améliore les performances : évite de recalculer le contenu du fil à chaque render,
  // car il y a bcp de render avec le PullToRefresh)
  useEffect(() => {
    if (newsFeedContent.length>=1) {
      setFeedList(newsFeedContent.map((item) => 
            <Post
              key={item.pk}
              postScreen="NewsFeed"
              post={item}
              refreshFunction={RefreshNewsFeed}
            />
      ))
    } else {
      if (subscribedMarkersArray.length>=1) {
        setFeedList(
          <Typography align="center" variant="body1" color="primary">
            Il n'y a pas encore de contribution dans les lieux que vous suivez.
          </Typography>
        )
      } else {
        setFeedList(
          <Typography align="center" variant="body1" color="primary">
            Vous ne suivez aucun lieu. Abonnez-vous à des spots et retrouvez ici leurs fils de discussion ! 
          </Typography>
        )
      }
    }
  }, [newsFeedContent])

  const RefreshNewsFeed = () => {
    setIsRefreshing(true)
    GetFunction({fetchTarget : 'newsFeedContent',fetchArgument : null,token : token})
    .then((response) => {
      setStartPoint(0);
      setPullChange(0);
      setIsRefreshing(false)
      if (response.fetchStatus === 'Ok') {
        console.log('NewsFeed.js -> Chargement newsFeedContent dans le state Redux')
        dispatch({ type : "LOAD_NEWS_FEED_CONTENT", payload:response.data})
      } else {
        console.log('NewsFeed.js -> Erreur dans le fetch newsFeedContent')
      }
    })
  };

  // 
  // GESTION DU PULL-TO-REFRESH
  //

  // Création des ref et des useEffect de mise à jour des ref quand les states changent
  // Parce que les listeners n'ont pas accès aux valeurs à jour des variables et des states (enclosure)
  const startPointRef = useRef();
  const pullChangeRef = useRef();
  const isRefreshingRef = useRef();

  useEffect(() => {
    startPointRef.current = startPoint
  }, [startPoint]);

  useEffect(() => {
    pullChangeRef.current = pullChange
  }, [pullChange]);
  
  useEffect(() => {
    isRefreshingRef.current = isRefreshing
  }, [isRefreshing]);

  // Création des 3 fonctions des listeners

  const pullStart = (e) => {
    // Pour empêcher de déclencher les listeners sur tous le séléments en même temps
    e.stopPropagation()
    const { screenY } = e.targetTouches[0];
    setStartPoint(screenY);
  };

  const pull = (e) => {
    e.stopPropagation()
    const touch = e.targetTouches[0];
    const { screenY } = touch;
    let pullLength = (startPointRef.current < screenY ? screenY - startPointRef.current : 0);
    
    const scrollableList = scrollableListRef.current;
    const scrollTop = scrollableList.scrollTop;
    if (scrollTop === 0 && pullLength > 0) {
      setPullChange(pullLength);
    }
  };

  const endPull = (e) => {
    e.stopPropagation()
    if (pullChangeRef.current > 100) {
      // Plusieurs listeners peuvent être déclenchés en même temps : il faut lancer l'action une seule fois
      if (isRefreshingRef.current === false) {
        RefreshNewsFeed()
      }
    } else {
      setStartPoint(0);
      setPullChange(0);
    }
  };

  // Déclaration des listeners

  const feedRef = useRef(null);
  const scrollableListRef = useRef(null);

  useEffect(() => {
    if (feedRef.current) {
      feedRef.current.removeEventListener("touchstart", pullStart, {passive : true});
      feedRef.current.removeEventListener("touchmove", pull, {passive : true});
      feedRef.current.removeEventListener("touchend", endPull, {passive : true});

      feedRef.current.addEventListener("touchstart", pullStart, {passive : true}); // L'écran est touché
      feedRef.current.addEventListener("touchmove", pull, {passive : true});       // Mouvement du doigt
      feedRef.current.addEventListener("touchend", endPull, {passive : true});     // L'écran est lâché
    }
  }, [feedRef.current]);

  // GESTION DES FAVORIS

  function SetSubscribedMarkersArray() {
    let newSubscribedMarkersArray = markersArray.filter((marker) => (subscriptionsArray.findIndex((id) => marker.pk === id.subscriptionSpot) !== -1))
    let nextSubscribedMarkersArray = newSubscribedMarkersArray.map(item => {
      let newItem
      if (item.spotType === "Port") {
        newItem = {...item, markerImgZoom : 15}
      } else if (item.spotType === "Mouillage") {
        newItem = {...item, markerImgZoom : 12}
      }
      return newItem
    });
    setSubscribedMarkersArray(nextSubscribedMarkersArray)
  }

  useEffect(() => {
    console.log('NewsFeed.js -> useEffect')
    SetSubscribedMarkersArray()
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
    },[subscriptionsArray]
  );   

  return(
    <Box sx={{display : "flex", flexDirection : "column", backgroundColor : "white"}} width="100%" height="100%" overflow = "auto" ref={scrollableListRef}> {/* Il faut préciser des dimensions pour pouvoir fixer ensuite une box en position absolute ou pour que les flex enfants prennent toute la place*/}
      {/* La boite du dessus du CircularProgress doit être à un zIndex supérieur*/}
      <Box sx={{
        display : "flex",
        flexDirection : "column",
        width : "100%",
        marginTop : 8,
        backgroundColor : "white",
        zIndex : 1001
        }}
      >
        <Typography  variant="h6" color="primary" fontWeight = "bold" sx={{marginLeft : 2}}>
          Mes favoris
        </Typography>

        <Box sx={{
          display : "flex",
          flexDirection : "row",
          width : "100%",
          borderBottom : 15, borderColor : "#FCE2D4"
          }}
          overflow = "auto"
        >
          {subscribedMarkersArray.map((item) =>
            <Box key = {item.pk.toString()} sx={{display : "flex", flexDirection : "column", alignItems : "center", margin : 2}}>
              <Avatar
                style={{
                  border: '3px solid #f3722C' // Seule synthaxe qui fonctionne pour créer un border 
                }}
                onClick={() => {navigate("/Feed/" + item.pk)}}
                src={"https://maps.googleapis.com/maps/api/staticmap?center=" + item.spotLatitude + "," + item.spotLongitude + "&zoom=" + item.markerImgZoom + "&size=400x400&maptype=satellite&key=AIzaSyATHwwDt6IBo65JOtA0bxsJ7G8r7lk4Fdk"}
                sx={{width: 80, height: 80}}
              />
              <Typography variant = "body2" align = "center" color={"primary"} onClick={() => navigate("/Feed/" + item.pk)} >
                {item.spotName}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {/* Section avec la référence de la zone scrollable */}
      <Box sx={{ flex : 1, position : "relative", flexDirection : "column", alignItems : "center"}} ref={feedRef}>
        {/* Définition d'un gradient de couleurs*/}
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="00%" y1="00%" x2="00%" y2="100%">
            <stop offset="0%" stopColor="red" />
              <stop offset="5%" stopColor="orange" />
              <stop offset="50%" stopColor="yellow" />
              <stop offset="70%" stopColor="green" />
              <stop offset="95%" stopColor="blue" />
              <stop offset="100%" stopColor="purple" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress 
          sx={{
            position : "absolute",
            top : -50 + pullChange/5,
            transition : "top 0.2s linear",
            // Le CircularProgress doit être à un zIndex jusyte en dessous de l'élément du haut, mais au dessus des élements stardard*/}
            zIndex : 1000,
            'svg circle': { stroke: 'url(#my_gradient)' },
          }}
        />
        {feedList}
      </Box>
      <Fab
        color="success"
        onClick={() => navigate("/MapPage" , {state : {newsFeedMultiplePost : true}})}
        sx={{margin : 1, position : "fixed", right : {xs : 20, sm : 30, md : 40}, bottom : {xs : 20, sm : 30, md : 40}}}
        >
        <SendIcon sx={{color : "white", fontSize: 40 }}/>
      </Fab>
    </Box>
  );
}