import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom'

export default function BackButton() {
    const navigate = useNavigate()
    return(
      <IconButton
        onClick={() => {navigate(-1)}}
        sx={{
          position: 'absolute',
          left: '.3rem',
          top: {xs : '.5rem',sm : '.7rem',md : '.9rem'},
          fontSize: 40,
          color:"white",
          zIndex : 1300
        }} 
      >
        <ArrowBackIosIcon/>
      </IconButton>
    )
  }
