import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Fade from '@mui/material/Fade';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';

import GetFunction from '../API/getFunction';
import PostFunction from '../API/postFunction';

import BackButton from '../Navigation/BackButton';

import { useState, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom'
import { Typography } from '@mui/material';

// DECLARATION REDUX
import { useSelector, useDispatch } from 'react-redux'

export default function OtherUserProfile() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const selectChatHeadersArray = state => state.chatHeadersArray
  const chatHeadersArray = useSelector(selectChatHeadersArray)

  const selectProfile = state => state.myProfile
  const myProfile = useSelector(selectProfile)

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  // On récupère le userId et le chatButtonDisplay passés en paramètre du lien
  let {userId} = useParams()

  const [userProfile, setUserProfile] = useState({
    userProfile: null,
    isFetching: true,
  })
  
  useEffect(() => {
    console.log('OtherUserProfile.js -> useEffect : chargemment du profil utilisateur')
    LoadOtherUserProfile()
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
    },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

    // Fonction de chargement du contenu du profil, lancée dans le useEffect
  function LoadOtherUserProfile() {
    GetFunction({fetchTarget : 'getOtherUserProfile', fetchArgument:userId, token:token})
    .then(response => {
      if(response.fetchStatus === 'Ok') {
        console.log('OtherUserProfile.js -> Fin chargement API getOtherUserProfile')
        console.log('OtherUserProfile.js -> Mise à jour du statut otherUserProfile')
        setUserProfile(prevState => ({...prevState, userProfile : response.data[0], isFetching : false})) 
      } else {
        console.log('OtherUserProfile.js -> Impossible de charger le contenu du profil')
        setUserProfile(prevState => ({...prevState, isFetching : false})) 

        let errorMessage = "Impossible d'afficher le profil de l'utilisateur , vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})

      }
    })
  }

  function ChatButton() {
    if (userProfile.userProfile) {
      console.log("userProfile :")
      console.log(userProfile)
      console.log("myProfile :")
      console.log(myProfile)
      if (userProfile.userProfile.pk !==myProfile.pk) {
        return (
          <Button
            onClick={ChatWithUser}
            variant="contained"
            endIcon={<EditIcon />}
            sx={{ ml: 2, mr:2 }}
            >
            Ecrire à {userProfile.userProfile.userNickname}          
          </Button>
        );
        } else return null
    } else return null
  }

  const profilePicture = () => {
    if (userProfile.userProfile.userPicurl) {
    return (
      <Avatar
      src={userProfile.userProfile.userPicurl}  
      sx={{ width: 100, height: 100 }}
    />
      )
    } else {
    return (
      <AccountCircleIcon color="primary" aria-label="upload picture" sx={{ fontSize: 100 }} />
    );
    }
  };

  const profileGlobalBadge = () => {
    if (userProfile.userProfile.userGlobalBadge) {
    return (
      <Avatar
      src={userProfile.userProfile.userGlobalBadge}  
      sx={{ width: 20, height: 20 }}
    />
      )
    } else {
    return null;
    }
  };

  function UserProfileScreen() {
    if (userProfile.userProfile) {
      if (!userProfile.isFetching) {
        return (
          <Fade in={true} timeout={200}>
            <Box sx={{ position : "absolute", top : 0, bottom : 0, left : 0, right : 0}}>
              {/* Fond grisé qui renvoie vers la page précédente */}
              <Box
                onClick = {() => {navigate(-1)}} 
                sx={{ position : "absolute", top : 0, bottom : 0, left : 0, right : 0, backgroundColor : "grey", opacity : 0.5}}
              />
              <Paper sx={{position : "absolute", top : {xs :80, md : 90}, bottom : 20, left : 20, right : 20, backgroundColor : "white", borderRadius : 1}} elevation={24} >
                {/* Croix pour fermer la fenêtre */}
                <Box sx={{position : "absolute", top : -20, right : -20, backgroundColor : "white", borderRadius : 10}} onClick = {() => {navigate(-1)}}>
                  <IconButton color="secondary">
                    <HighlightOffIcon fontSize = "large"/>
                  </IconButton>
                </Box>
                <Box sx={{
                  display : "flex",
                  flexDirection : "column",
                  height : "100%",
                  width : "100%",
                  alignItems : "center"
                  }}
                  overflow = "auto"
                >
                  {profilePicture()}
                  <Typography fontSize={"x-large"} color={"primary"} fontWeight={'bold'} textAlign={'center'}>
                    {userProfile.userProfile.userNickname}
                  </Typography>
                    {profileGlobalBadge()}
                  <TextField
                    fullWidth
                    id="userDescription"
                    label={(userProfile.userProfile.userIsHarbor ? "Le mot de la capitainerie": "Quelques mots sur moi")}
                    value={(userProfile.userProfile.userDescription?userProfile.userProfile.userDescription:" ")}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{marginTop : 2, marginBottom : 1}}
                    multiline
                    rows={4}
                  />
                  {(userProfile.userProfile.userIsHarbor ?
                    null
                    :
                    <TextField
                      fullWidth
                      id="userBoat"
                      label="Quelques mots sur mon bateau"
                      value={(userProfile.userProfile.userBoat?userProfile.userProfile.userBoat:" ")}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{marginTop : 1, marginBottom : 2}}
                      multiline
                      rows={4}
                    />
                  )}
                  {(userProfile.userProfile.userDisplayBoatType ?
                    <TextField
                      fullWidth
                      id="userBoatType"
                      label="Mon type de bateau"
                      value={userProfile.userProfile.userBoatType}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{marginTop : 1, marginBottom : 2}}
                      multiline
                      rows={4}
                    />
                    :
                    null
                  )}
                  {(userProfile.userProfile.userDisplayBoatName ?
                    <TextField
                      fullWidth
                      id="userBoatName"
                      label="Le nom de mon bateau"
                      value={userProfile.userProfile.userBoatName}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{marginTop : 1, marginBottom : 2}}
                      multiline
                      rows={4}
                    />
                    :
                    null
                  )}
                  {(userProfile.userProfile.userDisplayBoatModel ?
                    <TextField
                      fullWidth
                      id="userBoatModel"
                      label="Le modèle de mon bateau"
                      value={userProfile.userProfile.userBoatModel}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{marginTop : 1, marginBottom : 2}}
                      multiline
                      rows={4}
                    />
                    :
                    null
                  )}
                  {(userProfile.userProfile.userDisplayBoatHarbor ?
                    <TextField
                      fullWidth
                      id="userBoatHarbor"
                      label="Mon port d'attache"
                      value={userProfile.userProfile.userBoatHarbor}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{marginTop : 1, marginBottom : 2}}
                      multiline
                      rows={4}
                    />
                    :
                    null
                  )}
                  {ChatButton()}
                </Box>
              </Paper>
            </Box>
          </Fade>
        )
      } else {
        return (
          <Container component="main"
            sx={{
            backgroundColor: "#DDDDDD",
            }}
          >
            <CircularProgress />
          </Container>
        )
      }
    }
  }

  function ChatWithUser() {
    let userIndexInSubscription = chatHeadersArray.findIndex(item => (item.chatroomsubscriptions[0].subscriptionUser === userProfile.userProfile.pk))
    if (userIndexInSubscription === -1) { // On vérifie si un chat avec l'utilisateur existe déjà car sinon il faut le créer avant d'aller vers l'écran de chat
      console.log('OtherUserProfile.js -> Pas de chat commencé avec cet utilisateur : on crée un nouveau header')
      setUserProfile(prevState => ({...prevState, isFetching : true})) 
      let headerToCreate = {
        "chatroomsubscriptions": [
          {
              "subscriptionUser": myProfile.pk,
              "subscriptionUnreadMessages": 0
          },
          {
              "subscriptionUnreadMessages": 0,
              "subscriptionUser": userProfile.userProfile.pk
          }
        ]
      }
      PostFunction({fetchTarget : 'createChatHeader', fetchArgument : headerToCreate, token : token})
      .then(response => {
        let newChatRoom = response.data.chatroomsubscriptions[0].subscriptionChatroom
        setUserProfile(prevState => ({...prevState, isFetching : false})) 
        if (response.fetchStatus === 'Ok')  {
          console.log('OtherUserProfile.js -> Chargement du chatHeadersArray à jour')
          GetFunction({fetchTarget : 'chatHeadersArray', fetchArgument : null, token : token})
          .then((response) => {
            if (response.fetchStatus === 'Ok') {
              console.log('OtherUserProfile.js -> Chargement du chatHeadersArray dans le state Redux')
              dispatch({ type : "LOAD_CHAT_HEADERS_ARRAY", payload:response.data})
            } else {
              console.log('OtherUserProfile.js -> Impossible de charger la liste des conversations à jour')
            }
          })
          let nextPage = "/Chat/" + newChatRoom + "/" + userProfile.userProfile.pk
          navigate (nextPage)
        } else {
          console.log('OtherUserProfile.js -> Impossible de créer une conversation avec cet utilisateur')
        }
      })
    } else {
      console.log('OtherUserProfile.js -> Il y a déjà un chat commencé avec cet utilisateur : on y va')
      let existingChatroom = chatHeadersArray[userIndexInSubscription].chatroomsubscriptions[0].subscriptionChatroom
      let nextPage = "/Chat/" + existingChatroom + "/" + userProfile.userProfile.pk
      navigate (nextPage)
    }
  }

  return(
    <Container>
      <BackButton/>
      {/* Bloc pour dépasser sous le menu */}
        <Box sx={{height: {xs :40, md : 50}, backgroundColor : "#fdf0e9"}}/>
      <UserProfileScreen/>
    </Container>
  )
}