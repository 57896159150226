import { Fragment, useEffect} from "react";
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';
import { useDispatch  } from 'react-redux'
import { useTheme } from "@emotion/react";
import { keyframes } from '@emotion/react'

import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

import Zoom from '@mui/material/Zoom';

export default function TutorialStart() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()

  // Hook qui affiche la carte et masque les FABs à chaque mount
  useEffect(() => {
    console.log('NotificationList.js -> Action DISPLAY_MAP dans le store')
    dispatch({ type : "DISPLAY_MAP"})
    dispatch({type : "DISPLAY_FABS"})
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  // Hook qui masque la carte à chaque unmount
  useEffect(() => {
    return () => {
      console.log('NotificationList.js -> Action HIDE_MAP dans le store')
      dispatch({ type : "HIDE_MAP"})
      dispatch({type : "DISPLAY_FABS"})
    }
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  }, [])
  
  //
  // Animations standard
  //

  let effectRotate = keyframes`
  0% {
    transform: rotate(-20deg)
  }
  50% {
    transform: rotate(20deg)
  }
  100% {
    transform: rotate(-20deg)
  }`

  let animationRotate = `${effectRotate} 1s ease-in-out infinite`

  let effectScale = keyframes`
  0% {
    transform: scale(1)
  }
  50% {
    transform: scale(1.2)
  }
  100% {
    transform: scale(1)
  }`

  let animationScale = `${effectScale} 1s ease-in-out infinite`

  function AnimatedBox() {
    return(
      <Box
        component="img"
        src="https://img-31.ccm2.net/35qSlBONKYSxYr9ca9ESPNtc_w4=/170x116/smart/1a78e43df2134f6cb06152d94dd740c0/ccmcms-hugo/10585561.jpg"  // Lien vers l'image
        sx={{
          width : 100,
          height : 100,
          animation: animationRotate
        }}
      />
    )
  }

  function SpeakerBox() {
    return(
      <Box
        component="img"
        src="https://img-31.ccm2.net/35qSlBONKYSxYr9ca9ESPNtc_w4=/170x116/smart/1a78e43df2134f6cb06152d94dd740c0/ccmcms-hugo/10585561.jpg"  // Lien vers l'image
        sx={{
          width : 50,
          height : 50,
          margin : 5,
          animation: animationScale
        }}
      />
    )
  }

  function PreviousNextButtons() {
    return (
      <Box sx={{display : "flex", flexDirection : "row", alignItems : "center", justifyContent : "space-between", position : "fixed", bottom : 0, right : 1, left : 0, backgroundColor : "white", padding : 1, zIndex : theme.zIndex.modal-1}}>
        <Fab
          size="small"
          onClick={() => {
            navigate ("/MapPage", {replace : true})
          }}
          color="primary"
          sx={{
            ml: 2, mr: 2,
            zIndex : theme.zIndex.modal
          }}
        >
          <SkipPreviousIcon/>
        </Fab>
        <Fab
          size="small"
          onClick={() => {
            navigate ("/MapPage", {replace : true})
          }}
          color="error"
          sx={{
            ml: 2, mr: 2,
            zIndex : theme.zIndex.modal
          }}
        >
          <HighlightOffIcon />
        </Fab>
        <Fab
          size="small"
          onClick={() => {
            navigate ("/Tutorial1", {replace : true})
          }}
          color="primary"
          sx={{
            ml: 2, mr: 2,
            zIndex : theme.zIndex.modal
          }}
        >
          <SkipNextIcon/>
        </Fab>
      </Box>
    );
  }

  return (
    <Fragment>
      {/* COUCHE OPAQUE D'INTERCEPTION DES CLICS */}
      <Box sx={{
        display : "flex",
        position : "fixed",
        top : 0,
        bottom : 0,
        right : 0,
        left : 0,
        backgroundColor : "black",
        opacity : 0.4,
        zIndex : theme.zIndex.modal-1, // On se met juste au dessous du niveau des modal (Alert etc...)
      }}/>

      {PreviousNextButtons()}

      {/* BULLES DE TEXTE DU TUTO - FLECHE EN BAS*/}
      <Box sx={{
          display : "flex",
         flexDirection : "column",
          alignItems : "center",
          position : "fixed",
          top : {xs : "10%", sm : "20%", md : "30%"},
          left : {xs : "10%", sm : "20%", md : "30%"},
          right : {xs : "10%", sm : "20%", md : "30%"},
          zIndex : theme.zIndex.modal,
         }}
      >
      <Zoom in={true}>
      <Box 
        sx={{
          position : "relative",
          display: 'inline-block',
          padding: '16px',
          backgroundColor: 'white',
          borderRadius: '15px',
          boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.7)',
          zIndex : theme.zIndex.modal,
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: '-10px', // Placement du triangle en bas de la bulle
            left: '50%', // Ajuster pour le décaler par rapport à la bulle
            width: '0',
            height: '0',
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderTop: '10px solid white',
            transform: 'translateX(-50%)'
          },
        }}
      >

        <Typography variant = "h6" color={"primary"} paragraph={true}>
          Bienvenue sur NauticTalk, ou Nautictoc, ou même NautikTok!
        </Typography>
        <Typography color={"primary"} paragraph={true}>
          L'appli qui te permet de contacter les autres marins depuis ton bateau, un ponton ou ton canapé !
        </Typography>
        {AnimatedBox()}
      </Box>
      </Zoom>
      {SpeakerBox()}
      </Box>
    </Fragment>
  );
}
