import { Fragment, useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';
import { useDispatch  } from 'react-redux'
import { useTheme } from "@emotion/react";
import { keyframes } from '@emotion/react'

import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

import Zoom from '@mui/material/Zoom';

import CheckIcon from '@mui/icons-material/Check';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function TutorialEnd(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useTheme()

  // Hook qui affiche la carte et masque les FABs à chaque mount
  useEffect(() => {
    console.log('NotificationList.js -> Action DISPLAY_MAP dans le store')
    dispatch({ type : "DISPLAY_MAP"})
    dispatch({type : "DISPLAY_FABS"})
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  // Hook qui masque la carte à chaque unmount
  useEffect(() => {
    return () => {
      console.log('NotificationList.js -> Action HIDE_MAP dans le store')
      dispatch({ type : "DISPLAY_MAP"})
      dispatch({type : "DISPLAY_FABS"})
    }
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  }, [])


  let tutorialButtonEffect = keyframes`
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.15);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }`


  const [checkboxState, setCheckboxState] = useState(true);

  function CheckBoxElement() {
    return (
      <FormControlLabel
        control={<Checkbox checked={checkboxState} onChange={handleChangeCheckbox} color="primary" id="checkbox"/>}
        label={
          <Typography variant = "body2" color={"primary"} sx={{whiteSpace: 'pre-line'}}>
            Ne plus voir ce tuto au démarrage
          </Typography>
        }
      />
    );
  }

  const handleChangeCheckbox = (event) => {
    setCheckboxState(event.target.checked)
  };

  function PreviousNextButtons() {
    return (
      <Box sx={{display : "flex", flexDirection : "row", alignItems : "center", justifyContent : "space-between", position : "fixed", bottom : 0, right : 1, left : 0, backgroundColor : "white", padding : 1, zIndex : theme.zIndex.modal-1}}>
        <Fab
          size="small"
          onClick={() => {
            navigate ("/Tutorial9", {replace : true})
          }}
          color="primary"
          sx={{
            ml: 2, mr: 2,
            zIndex : theme.zIndex.modal
          }}
        >
          <SkipPreviousIcon/>
        </Fab>
        <Fab
          size="small"
          onClick={() => {
            navigate ("/MapPage", {replace : true})
          }}
          color="error"
          sx={{
            ml: 2, mr: 2,
            zIndex : theme.zIndex.modal
          }}
        >
          <HighlightOffIcon />
        </Fab>
        <Fab
          size="small"
          onClick={() => {
            if (checkboxState) {
              localStorage.setItem('NAUTICTALK_DO_NOT_SHOW_TUTORIAL', JSON.stringify({doNotShowTutorial : true}));
            } else {
              localStorage.removeItem('NAUTICTALK_DO_NOT_SHOW_TUTORIAL')
            }
            navigate ("/MapPage", {replace : true})
          }}
          color="success"
          sx={{
            ml: 2, mr: 2,
            zIndex : theme.zIndex.modal
          }}
        >
          <CheckIcon />
        </Fab>
      </Box>
    );
  }

  return (
    <Fragment>
      {/* COUCHE OPAQUE D'INTERCEPTION DES CLICS */}
      <Box sx={{
        display : "flex",
        position : "fixed",
        top : 0,
        bottom : 0,
        right : 0,
        left : 0,
        backgroundColor : "black",
        opacity : 0.4,
        zIndex : theme.zIndex.modal-1, // On se met juste au dessous du niveau des modal (Alert etc...)
      }}/>

      {PreviousNextButtons()}

      {/* BULLES DE TEXTE DU TUTO - FLECHE EN BAS*/}
      <Zoom in={true}>
        <Box 
          sx={{
            position: 'fixed',
            bottom : "20%",
            left : "10%",
            right : "10%",
            display: 'inline-block',
            padding: '16px',
            backgroundColor: 'white',
            borderRadius: '15px',
            boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.7)',
            zIndex : theme.zIndex.modal,
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: '-10px', // Placement du triangle en bas de la bulle
              left: '50%', // Ajuster pour le décaler par rapport à la bulle
              width: '0',
              height: '0',
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderTop: '10px solid white',
            },
          }}
        >
          <Typography variant = "h6" color={"primary"} paragraph={true}>
            A toi de jouer maintenant !
          </Typography>
          <Typography color={"primary"} paragraph={true}>
            Explore la carte, abonne-toi à tes spots favoris... et fais vivre notre communauté !
          </Typography>
          <Typography color={"primary"} paragraph={true}>
            Quand tu publies du contenu, quand tu commentes, quand tu likes... tu gagnes des points de fidélité ! En cumulant les points, tu gagnes des badges, tu peux modifier ton icone sur la carte... et la communauté sait que tu es actif.{"\n"}
          </Typography>
          <Typography color={"primary"} paragraph={true}>
            Bon vent à toi avec notre communauté !
          </Typography>
          {CheckBoxElement()}
        </Box>
      </Zoom>
    </Fragment>
  );
}